import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Block, ExtendCSS } from 'vcc-ui';
import { AspectRatio } from '@vcc-www/utils/getAspectRatioCSS';
import ParallaxElement from './ParallaxElement';

type Props = {
  aspectRatio: AspectRatio;
  scrollFactor: number;
  height: number;
};
const FACTOR = 0.3333;
const PARALLAX_SPEED = 0.15;
const ParallaxImage: React.FC<React.PropsWithChildren<Props>> = ({
  aspectRatio,
  scrollFactor,
  height,
  children,
}) => {
  const insideRef = useRef<HTMLDivElement>(null);
  const [compensatedHeight, setCompensatedHeight] = useState(0);

  const handleResize = useCallback(() => {
    window.requestAnimationFrame(() => {
      const insideRefBlock = insideRef.current;
      if (insideRefBlock) {
        const insideRectHeight = insideRefBlock.getBoundingClientRect().height;
        const compensatedH = Math.round(
          insideRectHeight - insideRectHeight * FACTOR,
        );
        setCompensatedHeight(compensatedH);
      }
    });
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <Block extend={blockCSS(compensatedHeight)}>
      <ParallaxElement
        aspectRatio={aspectRatio}
        factor={PARALLAX_SPEED}
        scrollFactor={scrollFactor}
        height={height}
      >
        <Block extend={insideCSS} ref={insideRef}>
          {children}
        </Block>
      </ParallaxElement>
    </Block>
  );
};

const blockCSS: (compensatedHeight: number) => ExtendCSS = (
  compensatedHeight,
) => ({
  height: `${compensatedHeight}px`,
});

const insideCSS: ExtendCSS = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
};

export default ParallaxImage;
