import React, { ForwardedRef, forwardRef } from 'react';
import { Block, ExtendCSS } from 'vcc-ui';
import SharedVideo from '@vcc-www/video';
import { ObjectFit, Image_DEPRECATED } from '@vcc-package/media/DEPRECATED';
import { AspectRatio } from '@vcc-www/utils/getAspectRatioCSS';
import { Sources } from '@vcc-www/utils/getMediaSources';
import ParallaxContainer from '@vcc-www/parallax/ParallaxContainer';
import { ResponsiveValue } from '@vcc-www/utils/getMediaQueryValues';

export type Props = {
  altText?: string;
  aspectRatio: AspectRatio;
  imageSources: Sources;
  showParallaxImage?: boolean;
  testId?: string;
  trackLabel?: string;
  ga3TrackLabel?: string;
  videoSourcesMp4?: Sources;
  videoSourcesWebM?: Sources;
  videoSubtitleTrack?: string;
  controls?: 'disabled' | 'enabled' | 'extended' | 'none' | 'forceDisable';
  loop?: boolean;
  centeredPlayIcon?: boolean;
  videoDescription?: string;
  shouldAutoplay?: boolean;
  currentTime?: number;
  offsetYVideoControl?: number;
  offsetXVideoControl?: number;
  imageRef?: React.MutableRefObject<HTMLImageElement | null>;
  customizePlayIconSIze?: number;
  children?: React.ReactNode;
  playPauseButtonTabIndex?: number;
  videoCtaAutoId?: string;
  onEnded?: () => void;
  onPlay?: () => void;
  onImageLoaded?: () => void;
  onErrorImageFallback?: () => void;
  videoId?: string;
  preload?: 'none' | 'metadata' | 'auto';
  hideControlsOnPause?: boolean;
  loading?: 'eager' | 'native-lazy';
  shouldPause?: boolean;
  imageDensity?: number;
  imageObjectFit?: ObjectFit;
  sizes?: ResponsiveValue<string>;
  fetchPriority?: 'high' | 'low' | 'auto';
};

const VideoOrImage = forwardRef<HTMLDivElement, Props>(
  (
    {
      altText,
      aspectRatio,
      imageSources,
      showParallaxImage,
      testId,
      trackLabel,
      ga3TrackLabel,
      videoSourcesMp4,
      videoSourcesWebM,
      videoSubtitleTrack,
      controls,
      loop,
      videoDescription,
      shouldAutoplay,
      currentTime,
      offsetYVideoControl,
      offsetXVideoControl,
      centeredPlayIcon,
      imageRef,
      customizePlayIconSIze,
      children,
      playPauseButtonTabIndex,
      videoCtaAutoId,
      onEnded,
      onPlay,
      onImageLoaded,
      onErrorImageFallback,
      videoId,
      preload,
      hideControlsOnPause,
      loading,
      shouldPause,
      imageDensity,
      imageObjectFit,
      sizes,
      fetchPriority,
    },
    ref?: ForwardedRef<HTMLDivElement>,
  ) => {
    const hasVideo =
      (videoSourcesMp4 && Object.values(videoSourcesMp4).some(Boolean)) ||
      (videoSourcesWebM && Object.values(videoSourcesWebM).some(Boolean));

    return (
      <Block
        extend={{ width: '100%', height: 'inherit' }}
        data-testid={testId}
        ref={ref}
      >
        {hasVideo ? (
          <SharedVideo
            data-video-id={videoId}
            offsetYVideoControl={offsetYVideoControl}
            offsetXVideoControl={offsetXVideoControl}
            aspectRatio={aspectRatio}
            poster={imageSources}
            srcMp4={videoSourcesMp4}
            srcWebM={videoSourcesWebM}
            subtitleTrack={videoSubtitleTrack}
            centeredPlayIcon={centeredPlayIcon}
            trackLabel={trackLabel}
            ga3TrackLabel={ga3TrackLabel}
            controls={controls}
            loop={loop}
            videoDescription={videoDescription}
            shouldAutoplay={shouldAutoplay}
            currentTime={currentTime}
            customizePlayIconSIze={customizePlayIconSIze}
            playPauseButtonTabIndex={playPauseButtonTabIndex}
            videoCtaAutoId={videoCtaAutoId}
            onEnded={onEnded}
            onPlay={onPlay}
            preload={preload}
            hideControlsOnPause={hideControlsOnPause}
            shouldPause={shouldPause}
            fetchPriority={fetchPriority}
            loading={loading === 'eager' ? 'eager' : 'lazy'}
          >
            {children}
          </SharedVideo>
        ) : showParallaxImage ? (
          <ParallaxContainer aspectRatio={aspectRatio}>
            <Image_DEPRECATED
              loading={loading || 'native-lazy'}
              sizes={sizes}
              sources={imageSources}
              alt={altText || ''}
              aspectRatio={aspectRatio}
              objectFit={imageObjectFit || 'cover'}
              extend={imageCSS}
              onLoad={onImageLoaded}
              imageDensity={imageDensity}
              fetchPriority={fetchPriority}
            />
          </ParallaxContainer>
        ) : (
          <>
            <Image_DEPRECATED
              loading={loading || 'native-lazy'}
              sizes={sizes}
              sources={imageSources}
              alt={altText || ''}
              imageRef={imageRef}
              aspectRatio={aspectRatio}
              objectFit={imageObjectFit || 'cover'}
              extend={imageCSS}
              onError={onErrorImageFallback}
              onLoad={onImageLoaded}
              imageDensity={imageDensity}
              fetchPriority={fetchPriority}
            />
            {children}
          </>
        )}
      </Block>
    );
  },
);

const imageCSS: ExtendCSS = ({ theme: { color } }) => ({
  backgroundColor: color.background.secondary,
});

VideoOrImage.displayName = 'VideoOrImage';

export default VideoOrImage;
