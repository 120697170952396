import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Block, ExtendCSS } from 'vcc-ui';
import { AspectRatio } from '@vcc-www/utils/getAspectRatioCSS';
import ParallaxImage from './ParallaxImage';

type Props = {
  aspectRatio: AspectRatio;
};

const ParallaxContainer: React.FC<React.PropsWithChildren<Props>> = ({
  aspectRatio,
  children,
}) => {
  const containerRectRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [scrollFactor, setScrollFactor] = useState(0);

  const eventHandler = useCallback(
    () =>
      window.requestAnimationFrame(() => {
        const containerRectBlock = containerRectRef.current;

        if (containerRectBlock) {
          const containerRect = containerRectBlock.getBoundingClientRect();
          const containerRectHeight = containerRect.height;
          setHeight(containerRectHeight);

          const viewportOffsetTop = containerRect.top;
          const windowInnerHeight = window.innerHeight;
          const viewportOffsetBottom = windowInnerHeight - viewportOffsetTop;

          const scrollFactor =
            viewportOffsetBottom / (windowInnerHeight + containerRectHeight);
          setScrollFactor(scrollFactor);
        }
      }),
    [],
  );

  useEffect(() => {
    eventHandler();
    window.addEventListener('scroll', eventHandler);
    window.addEventListener('resize', eventHandler);
    return () => {
      window.removeEventListener('scroll', eventHandler);
      window.removeEventListener('resize', eventHandler);
    };
  }, [eventHandler]);

  return (
    <Block extend={blockCSS} ref={containerRectRef}>
      <ParallaxImage
        aspectRatio={aspectRatio}
        scrollFactor={scrollFactor}
        height={height}
      >
        {children}
      </ParallaxImage>
    </Block>
  );
};

const blockCSS: ExtendCSS = () => ({
  overflow: 'hidden',
});

export default ParallaxContainer;
