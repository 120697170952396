import React from 'react';
import { Block, ExtendCSS } from 'vcc-ui';
import {
  getAspectRatioCSS,
  AspectRatio,
} from '@vcc-www/utils/getAspectRatioCSS';

type Props = {
  aspectRatio: AspectRatio;
  factor: number;
  scrollFactor: number;
  height: number;
};

const ParallaxElement: React.FC<React.PropsWithChildren<Props>> = ({
  aspectRatio,
  factor,
  scrollFactor,
  height,
  children,
}) => (
  <Block extend={parallaxElementCSS(height, scrollFactor, factor, aspectRatio)}>
    {children}
  </Block>
);

const parallaxElementCSS: (
  height: number,
  scrollFactor: number,
  factor: number,
  aspectRatio: AspectRatio,
) => ExtendCSS =
  (height, scrollFactor, factor, aspectRatio) =>
  ({ theme: { breakpoints } }) => {
    const offSet = scrollFactor * height * factor;
    return {
      position: 'relative',
      top: '-40%',
      height: 0,
      overflow: 'hidden',
      transform: `translate3d(0px, ${offSet}px, 0px)`,
      ...(aspectRatio && {
        ...getAspectRatioCSS(breakpoints, aspectRatio),
      }),
    };
  };

export default ParallaxElement;
